import { Button } from 'components/shared/button';
import { Heading } from 'components/shared/heading';
import { HeroFrame } from 'components/shared/hero-frame';
import React from 'react';
import Arrow from 'svg/arrow.inline.svg';
import { app } from 'utils/urls';

import styles from './loadrunner-alternative-hero.module.scss';
import chartSvg from './svg/chart.svg';

export const Hero = () => (
  <section className={styles.wrapper}>
    <div className={`container ${styles.inner}`}>
      <div className={'row'}>
        <div className={'col-lg-5 col-12'}>
          <Heading className={styles.title} tag={'h1'}>
            Looking for a LoadRunner alternative?
          </Heading>
          <p className={styles.description}>
            Join thousands of engineering teams shifting left with&nbsp;k6 to accelerate your performance testing.
          </p>
          <div className={styles.buttonWrapper}>
            <Button
              tag={'a'}
              className={styles.btn}
              href={'https://grafana.com/auth/sign-up/create-user?src=k6io'}
              cursor
            >
              Create free account
            </Button>
            <Arrow className={styles.arrow} />
            <span className={styles.note}>500 VUh/month</span>
          </div>
          <p className={styles.demoLinkWrapper}>
            <a className={'link'} href={'https://go2.grafana.com/k6-contact.html'}>
              Request demo &gt;
            </a>
          </p>
        </div>
        <div className={'col-lg-7'}>
          <div className={styles.imageWrapper}>
            <img src={chartSvg} className={styles.image} alt={'Shift-left performance testing'} />
          </div>
        </div>
      </div>
    </div>

    <HeroFrame backgroundLabel={styles.frameWrapper} />
  </section>
);
