/* eslint-disable max-len */
import { SEO } from 'components/blocks/seo';
import { Hero } from 'components/pages/loadrunner-alternative/hero';
import { Advantages } from 'components/shared/alternative-advantages';
import { AlternativeFeedbacks } from 'components/shared/alternative-feedbacks';
import Bulletproofs from 'components/shared/bulletproofs';
import { CTA } from 'components/shared/cta';
import { Partners } from 'components/shared/partners';
import { graphql, useStaticQuery } from 'gatsby';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

const LoadrunnerAlternative = () => {
  const {
    allTestingFeaturesDataJson: { edges: features },
  } = useStaticQuery(graphql`
    query loadrunnerFeatures {
      allTestingFeaturesDataJson {
        edges {
          node {
            title
            description
            link
          }
        }
      }
    }
  `);
  return (
    <DefaultLayout>
      <Hero />
      <Advantages title={'Why k6 is a better alternative to LoadRunner'} />
      <AlternativeFeedbacks />
      <Bulletproofs
        title={'Feature-rich and easy-to-use load testing platform'}
        description={'Get everything your team needs to get your performance testing on the right track.'}
        bullets={features.map((node) => ({ ...node }))}
        showLines
        cta={{
          link: 'https://k6.io/blog/k6-vs-loadrunner/',
          text: 'read the comparison',
        }}
      />
      <Partners
        title={'Trusted and loved by more than 6000 companies for performance testing, including:'}
        onlyCustomers
      />
      <CTA
        title={'Not sure where to start?'}
        description={'In this post, we do an overview analysis comparing the main areas and uses of k6 and LoadRunner.'}
        buttonText={'Read the comparison'}
        buttonRef={'https://k6.io/blog/k6-vs-loadrunner/'}
        themeLight
      />
    </DefaultLayout>
  );
};

export default LoadrunnerAlternative;

export const Head = () => <SEO {...SEO_DATA['loadrunner-alternative']} />;
